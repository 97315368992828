'use client'

import { InView } from 'react-intersection-observer'
import { ReactNode, use, useRef } from 'react'

import { VasEntryPointModel } from 'types/models/vas-entry-point'
import { ClosetModel } from 'types/models/closet'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { impressionEvent } from '_libs/common/event-tracker/events'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ContentSource } from 'constants/tracking/content-sources'

import { VasEntryPoint } from '../../constants/vas-entry-point'
import ClosetPromotion from './ClosetPromotion'
import DynamicSpacer from '../DynamicSpacer'

type Props = {
  position: number
  vasEntryPoints?: Array<VasEntryPointModel>
  homepageSessionId: string
  suffix?: ReactNode
  closet: ClosetModel | Promise<ClosetModel | null>
  renderFallback?: () => ReactNode
}

const HomeClosetPromotion = (props: Props) => {
  const { track } = useTracking()
  const isSeen = useRef(false)

  const closet = props.closet instanceof Promise ? use(props.closet) : props.closet
  if (!closet) return props.renderFallback?.()

  const closetPromoBanner = closet.showBanner
    ? props.vasEntryPoints?.find(entryPoint => entryPoint.name === VasEntryPoint.PromotedClosets)
    : undefined

  const handleVisible = (inView: boolean) => {
    if (isSeen.current) return
    if (!inView) return

    isSeen.current = true

    track(
      impressionEvent({
        id: closet.user.id,
        position: props.position,
        contentType: ListItemContentType.PromotedCloset,
        contentSource: ContentSource.PromotedClosets,
        homepageSessionId: props.homepageSessionId,
      }),
    )
  }

  return (
    <>
      <DynamicSpacer phones="Regular" tabletsUp="Large" />
      <InView onChange={handleVisible}>
        <ClosetPromotion wide banner={closetPromoBanner} {...props} closet={closet} />
      </InView>
      {props.suffix}
    </>
  )
}

export default HomeClosetPromotion
