'use client'

import { isEqual } from 'lodash'
import { ReactNode, Suspense, useRef } from 'react'

import { HomeAdvertisement } from '@marketplace-web/domain/ads'
import { ClosetModel } from 'types/models/closet'
import { VasEntryPointModel } from 'types/models/vas-entry-point'
import { useAbTest } from '@marketplace-web/shared/ab-tests'

import HomeClosetPromotion from '../components/ClosetPromotion/HomeClosetPromotion'
import ClosetSkeleton from '../components/ClosetSkeleton'

type Ad = 'ad' | 'fallback-ad'
type PendingCloset = ClosetModel | Promise<ClosetModel | null>
type Entity = 'empty' | 'ad' | 'fallback-ad' | PendingCloset

const isPromise = (item: unknown): item is Promise<unknown> => item instanceof Promise
const isAd = (item: Entity): item is Ad => item === 'ad' || item === 'fallback-ad'
const isEmpty = (item: Entity): item is 'empty' => item === 'empty'
const isCloset = (item: Entity): item is PendingCloset => !isAd(item) && !isEmpty(item)

type Props = {
  vasEntryPoints?: Array<VasEntryPointModel>
  closets: Array<ClosetModel>
  arePromotedClosetsEnabled: boolean
  areAdsEnabled: boolean
  homepageSessionId: string
  fetchMoreClosets?: () => Promise<Array<ClosetModel> | undefined>
}

type RenderProps = {
  position: number
  id: string | number
  suffix?: ReactNode
  renderFallback?: () => ReactNode
}

const useHomeClosetOrAd = ({
  vasEntryPoints,
  closets,
  areAdsEnabled,
  arePromotedClosetsEnabled,
  homepageSessionId,
  fetchMoreClosets,
}: Props) => {
  const closetAbTest = useAbTest('web_homepage_lazy_load_closet')
  const isClosetLoadedLazily = closetAbTest?.variant === 'on'
  const renderedSequence = useRef<Array<Entity>>([])
  let pendingClosetIndex = 0
  let currentIndex = 0

  const fetchMore = async () => {
    const closetIndex = pendingClosetIndex
    pendingClosetIndex += 1 // Avoids duplicates if >1 closets are pending
    const result = await fetchMoreClosets?.()

    return result?.[closetIndex] ?? null
  }

  const getRemainingCloset = () => {
    if (!arePromotedClosetsEnabled) return null
    const remainingClosets = closets.filter(closet => {
      const wasClosetRendered = renderedSequence.current.some(value => isEqual(closet, value))

      return !wasClosetRendered
    })

    if (!isClosetLoadedLazily && remainingClosets.length <= 1) fetchMoreClosets?.()

    return remainingClosets[0] || (isClosetLoadedLazily && fetchMore()) || null
  }

  const getNewClosetOrAd = (index: number): Entity => {
    const shouldRenderCloset = index % 2 === 0

    if (shouldRenderCloset) return getRemainingCloset() ?? 'fallback-ad'

    return 'ad'
  }

  const renderFallbackAd = (props: RenderProps) => {
    if (!areAdsEnabled) return null

    return <HomeAdvertisement {...props} />
  }

  const renderCloset = (closet: PendingCloset, props: RenderProps) => {
    const commonProps = { ...props, vasEntryPoints, homepageSessionId }

    if (isPromise(closet)) {
      return (
        <Suspense fallback={<ClosetSkeleton suffix={props.suffix} />}>
          <HomeClosetPromotion {...commonProps} closet={closet} />
        </Suspense>
      )
    }

    return <HomeClosetPromotion {...commonProps} closet={closet} />
  }

  const renderFallbackCloset = (props: RenderProps, index: number) => {
    const renderedFallback = renderedSequence.current[index]
    if (renderedFallback && isCloset(renderedFallback)) return renderCloset(renderedFallback, props)

    const fallback = getRemainingCloset()
    if (!fallback) {
      // Previously it rendered an ad, so we replace it to not render the ad again
      renderedSequence.current[index] = 'empty'

      return null
    }
    if (isPromise(fallback)) {
      fallback.then(result => {
        renderedSequence.current[index] = result ?? 'empty'
      })
    }

    renderedSequence.current[index] = fallback

    return renderCloset(fallback, props)
  }

  const renderClosetOrAdComponent = (props: RenderProps) => {
    const index = currentIndex
    const entity = renderedSequence.current[index] ?? getNewClosetOrAd(index)
    renderedSequence.current[index] = entity

    if (isPromise(entity)) {
      entity.then(result => {
        renderedSequence.current[index] = result ?? 'fallback-ad'
      })
    }

    currentIndex += 1

    if (isEmpty(entity)) return null
    if (entity === 'ad') {
      if (!areAdsEnabled) return renderFallbackCloset(props, index)

      return (
        <HomeAdvertisement {...props} renderFallback={() => renderFallbackCloset(props, index)} />
      )
    }
    if (entity === 'fallback-ad') return renderFallbackAd(props)

    return renderCloset(entity, { ...props, renderFallback: () => renderFallbackAd(props) })
  }

  return {
    renderClosetOrAdComponent,
  }
}

export default useHomeClosetOrAd
